var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "fieldset",
                {
                  attrs: {
                    disabled:
                      !_vm.amountsAreOkay ||
                      _vm.invoiceData.status.plain == "closed"
                  }
                },
                [
                  _c("div", { staticClass: "invoice-title" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "float-right font-size-16",
                        on: { click: _vm.getInvoiceDetails }
                      },
                      [
                        _vm._v(" Facture N° # "),
                        _c("b", [_vm._v(_vm._s(_vm.invoiceData.ref))]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.invoiceData.status.rendered)
                          }
                        }),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.invoiceData.standby_html)
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "button-items" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.invoiceData.status.plain != "draft" ||
                                  _vm.invoiceData.standby,
                                variant: "outline-success"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.approve($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-check" }),
                              _vm._v(" Approuver")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.invoiceData.standby,
                                variant: "outline-danger"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showInvoiceCancelModal($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-window-close" }),
                              _vm._v(" Annuler")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.invoiceData.standby,
                                variant: "outline-warning"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showRejectionModal($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-window-close" }),
                              _vm._v(" Bloquer")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.invoiceData.standby,
                                variant: "outline-success"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.unblockInvoice($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-window-close" }),
                              _vm._v(" Débloquer")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.invoiceData.status.plain != "approved" ||
                                  _vm.invoiceData.payment != null ||
                                  _vm.invoiceData.standby,
                                variant: "outline-info"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.registerPaymentClick($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-money-bill" }),
                              _vm._v(" Enregistrer un paiement")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.invoiceData.status.plain != "approved" ||
                                  _vm.invoiceData.standby,
                                variant: "outline-warning"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.archive($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-archive" }),
                              _vm._v(" Clôturer")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.invoiceData.status.plain == "closed" ||
                                  _vm.invoiceData.status.plain == "draft" ||
                                  _vm.invoiceData.status.plain == "canceled" ||
                                  _vm.invoiceData.standby,
                                variant: "outline-secondary"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setDraft($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-undo" }),
                              _vm._v(" Remettre en brouillon")
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              _c("hr"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Facturé à:")]),
                    _c("br"),
                    _vm._v("SONATRACH "),
                    _c("br"),
                    _vm._v("Direction de production "),
                    _c("br"),
                    _vm._v("Direction régionale IRARA "),
                    _c("br"),
                    _vm._v("Hassi Messoud, Ouergla ")
                  ])
                ]),
                _c("div", { staticClass: "col-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Prestataire:")]),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.invoiceData.contract.supplier.prestname) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.invoiceData.contract.supplier.adress1) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.invoiceData.contract.supplier.adress2)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.invoiceData.contract.supplier.adress3) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "address",
                    [
                      _c("strong", [_vm._v("Infos Paiement:")]),
                      _c("br"),
                      _c("b", [_vm._v("Status:")]),
                      _vm.invoiceData.payment
                        ? [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.invoiceData.payment.status.rendered
                                )
                              }
                            }),
                            _vm.invoiceData.payment.status.plain == "blocked"
                              ? _c("span", { staticClass: "ml-3" }, [
                                  _c("b", [_vm._v("Motif de bloquage: ")]),
                                  _vm._v(
                                    _vm._s(_vm.invoiceData.payment.comments) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _vm.invoiceData.payment.status.plain == "rejected"
                              ? _c("span", { staticClass: "ml-3" }, [
                                  _c("b", [_vm._v("Motif de Rejet: ")]),
                                  _vm._v(
                                    _vm._s(_vm.invoiceData.payment.comments) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _c("br")
                          ]
                        : _vm._e(),
                      _c("b", [_vm._v("Ref:")]),
                      _c(
                        "span",
                        {
                          staticClass: "text-primary",
                          on: { click: _vm.editPaymentClick }
                        },
                        [
                          _vm._v(
                            " #" +
                              _vm._s(
                                _vm.invoiceData.payment
                                  ? _vm.invoiceData.payment.ref
                                  : "N/A"
                              ) +
                              " "
                          ),
                          _vm.invoiceData.payment
                            ? [
                                _vm.invoiceData.payment.status.plain == "draft"
                                  ? _c("i", { staticClass: "fas fa-edit" })
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Date:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.invoiceData.payment
                              ? _vm.invoiceData.payment.date
                              : "N/A"
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("b", [_vm._v("N° OV:")]),
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(
                          "#" +
                            _vm._s(
                              _vm.invoiceData.payment
                                ? _vm.invoiceData.payment.n_ov
                                : "N/A"
                            )
                        )
                      ]),
                      _c("br"),
                      _c("b", [_vm._v("Document Electronique:")]),
                      _vm.invoiceData.payment
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.invoiceData.payment.ov_file.fullPath,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v("Télécharger "),
                              _c("i", { staticClass: "fas fa-download" })
                            ]
                          )
                        : _c("span", { staticClass: "text-primary" }, [
                            _vm._v(" N/A")
                          ]),
                      _c("br"),
                      _c("b", [_vm._v("Par:")]),
                      _c("a", { attrs: { href: "javascript:void(0)" } }, [
                        _vm._v(
                          " #" +
                            _vm._s(
                              _vm.invoiceData.payment
                                ? _vm.invoiceData.payment.p_by
                                : "N/A"
                            ) +
                            " "
                        )
                      ])
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "col-3" })
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3 mt-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Mode de paiement:")]),
                    _c("br"),
                    _vm._v("Virement bancaire "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.invoiceData.contract.sp_domiciliation) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-3 mt-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Date de facture:")]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.invoiceData.dates.issueDate) + " "),
                    _c("br"),
                    _c("br")
                  ])
                ]),
                _c("div", { staticClass: "col-3 mt-3" }, [
                  _c("address", [
                    _c("strong", [_vm._v("Date de réception facture:")]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.invoiceData.dates.receptionDate) + " "),
                    _c("br"),
                    _c("br")
                  ])
                ]),
                _vm.invoiceData.isRestitutionInvoice
                  ? _c("div", { staticClass: "col-3 mt-3" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "badge badge-pill badge-soft-success font-size-11"
                        },
                        [
                          _c("h5", [
                            _vm._v("Restitution de garantie du contrat: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.invoiceData.contract.ref))
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              !_vm.invoiceData.isRestitutionInvoice
                ? _c("div", { staticClass: "p-2 mt-3" }, [
                    _c("h3", { staticClass: "font-size-16" }, [
                      _vm._v("Liste des attachements")
                    ]),
                    _vm.invoiceData.status.plain == "draft" &&
                    !_vm.amountsAreOkay
                      ? _c(
                          "a",
                          {
                            staticClass: "font-size-9 float-right",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.addAttachmentsClick($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(" Ajouter des attachements")
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _c("h3", { staticClass: "font-size-16 float-right" }, [
                      _vm.amountsAreOkay
                        ? _c("span", { staticClass: "badge badge-success" }, [
                            _vm._v("Montants conformes")
                          ])
                        : _c("span", { staticClass: "badge badge-danger" }, [
                            _vm._v("Montants Non conformes")
                          ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "table-responsive" }, [
                !_vm.invoiceData.isRestitutionInvoice
                  ? _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticStyle: { width: "70px" } }, [
                            _vm._v("No.")
                          ]),
                          _c("th", [_vm._v("N° Attachement")]),
                          _c("th", [_vm._v("Date de création")]),
                          _c("th", [_vm._v("Créer par")]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v("Sous total")
                          ])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.invoiceData.attachmentsList, function(
                          attachment,
                          index
                        ) {
                          return _c(
                            "tr",
                            {
                              key: attachment.id,
                              staticStyle: { "font-weight": "bold" }
                            },
                            [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        to: {
                                          name: "invoicing.display",
                                          params: { uid: attachment.uuid }
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(attachment.ref) + " ")]
                                  ),
                                  _vm.invoiceData.status.plain == "draft"
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.detachSingleAttachmentFromInvoice(
                                                attachment
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-trash text-danger ml-2"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(attachment.dates.generatedAt) +
                                    " "
                                )
                              ]),
                              _c("td", [_vm._v("#")]),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(attachment.total_amnt.withCurrency) +
                                    " "
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c(
                        "td",
                        { staticClass: "text-right", attrs: { colspan: "4" } },
                        [_vm._v("Total Attachements")]
                      ),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.invoiceData.sumAttachments.withCurrency
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c(
                        "td",
                        { staticClass: "text-right", attrs: { colspan: "4" } },
                        [_vm._v("Total HT")]
                      ),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.invoiceData.untaxed_amnt.withCurrency) +
                            " "
                        )
                      ])
                    ]),
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c(
                        "td",
                        {
                          staticClass: "border-0 text-right",
                          attrs: { colspan: "4" }
                        },
                        [_c("strong", [_vm._v("Garantie")])]
                      ),
                      _c("td", { staticClass: "border-0 text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.invoiceData.appliedWarranty.withCurrency
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c(
                        "td",
                        {
                          staticClass: "border-0 text-right",
                          attrs: { colspan: "4" }
                        },
                        [_c("strong", [_vm._v("Pénalités")])]
                      ),
                      _c("td", { staticClass: "border-0 text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.invoiceData.appliedPenalty.withCurrency
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c(
                        "td",
                        {
                          staticClass: "border-0 text-right",
                          attrs: { colspan: "4" }
                        },
                        [_c("strong", [_vm._v("Tax")])]
                      ),
                      _c("td", { staticClass: "border-0 text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.invoiceData.tax_amnt.withCurrency) +
                            " "
                        )
                      ])
                    ]),
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c(
                        "td",
                        {
                          staticClass: "border-0 text-right",
                          attrs: { colspan: "4" }
                        },
                        [_c("strong", [_vm._v("Total TTC")])]
                      ),
                      _c("td", { staticClass: "border-0 text-right" }, [
                        _c("h2", { staticClass: "m-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.invoiceData.finalTotal.withCurrency) +
                              " "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c(
        "Modal",
        {
          attrs: {
            title:
              "Détails des prestations: " + _vm.singleServiceDetails.service,
            modalClass: "fullWidthModal"
          },
          model: {
            value: _vm.showSingleServiceDetailsModal,
            callback: function($$v) {
              _vm.showSingleServiceDetailsModal = $$v
            },
            expression: "showSingleServiceDetailsModal"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", {}, [
                _c("div", {}, [
                  _c("h4", { staticClass: "card-title" }),
                  _c("p", { staticClass: "card-title-desc" }),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-striped mb-0" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("N°")]),
                          _c("th", [_vm._v("N° ODS")]),
                          _c("th", [_vm._v("Date de demande")]),
                          _c("th", [_vm._v("Date d'exécution")]),
                          _c("th", [_vm._v("Montant Globale de l'ODS")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.singleServiceDetails.odsList, function(
                          ods,
                          index
                        ) {
                          return _c("tr", { key: ods.id }, [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(_vm._s(index + 1))
                            ]),
                            _c("td", [
                              _c("span", { staticClass: "text-primary" }, [
                                _vm._v(_vm._s(ods.ref))
                              ])
                            ]),
                            _c("td", [_vm._v(_vm._s(ods.date_ODS))]),
                            _c("td", [_vm._v(_vm._s(ods.date_exec_ODS))]),
                            _c("td", [
                              _c("b", [
                                _vm._v(_vm._s(ods.totalAmnt.withCurrency))
                              ])
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "Approuver l'attachement N°: " + _vm.invoiceData.ref
          },
          model: {
            value: _vm.events.showApprovalModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showApprovalModal", $$v)
            },
            expression: "events.showApprovalModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.approveInvoice($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Facture *")]),
                    _c(
                      "vue-dropzone",
                      {
                        ref: "myVueDropzone",
                        staticClass: "ebs-upload",
                        attrs: {
                          id: "dropzone",
                          "use-custom-slot": true,
                          options: _vm.dropzoneOptions
                        },
                        on: { "vdropzone-complete": _vm.afterComplete },
                        model: {
                          value: _vm.invoiceAttachment,
                          callback: function($$v) {
                            _vm.invoiceAttachment = $$v
                          },
                          expression: "invoiceAttachment"
                        }
                      },
                      [
                        _c("div", { staticClass: "dropzone-custom-content" }, [
                          _c("i", {
                            staticClass: "display-4 text-muted fas fa-upload"
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("h4", [
                            _vm._v(
                              "Déposez les fichiers ici ou cliquez pour télécharger."
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Commentaires")]),
                  _c("textarea", {
                    staticClass: "form-control",
                    attrs: { name: "", id: "", cols: "30", rows: "3" }
                  })
                ])
              ]),
              _c("br"),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fas fa-check" }),
                    _vm._v(" Approuver ")
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Bloquer la facture N°: " + _vm.invoiceData.ref },
          model: {
            value: _vm.events.showRejectionModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showRejectionModal", $$v)
            },
            expression: "events.showRejectionModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.rejectInvoice($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Raison de bloquage")
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bloquageData.comments,
                        expression: "bloquageData.comments"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "",
                      required: "",
                      id: "",
                      cols: "30",
                      rows: "3"
                    },
                    domProps: { value: _vm.bloquageData.comments },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.bloquageData,
                          "comments",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger float-right",
                      attrs: { type: "submit" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-window-close" }),
                      _vm._v(" Bloquer ")
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            title:
              "Enregistrer un paiement pour la facture N°: " +
              _vm.invoiceData.ref
          },
          model: {
            value: _vm.events.showRegisterPaymentModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showRegisterPaymentModal", $$v)
            },
            expression: "events.showRegisterPaymentModal"
          }
        },
        [
          _c(
            "form",
            {
              attrs: { enctype: "multipart/form-data" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.registerInvoicePayment($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Status *")
                      ]),
                      _c("multiselect", {
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.paymentData.status.$error
                        },
                        attrs: {
                          searchable: true,
                          "track-by": "id",
                          "select-label": "",
                          label: "name",
                          options: _vm.paymentStatusList,
                          placeholder: "Select Payment Status",
                          "allow-empty": false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "singleType",
                            fn: function(ref) {
                              var type = ref.type
                              return [_vm._v(_vm._s(type.name))]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.paymentData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.paymentData, "status", $$v)
                          },
                          expression: "paymentData.status"
                        }
                      }),
                      _vm.submitted && _vm.$v.paymentData.status.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.paymentData.status.required
                              ? _c("span", [_vm._v("Champ Obligatoire.")])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Date *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paymentData.date,
                          expression: "paymentData.date"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.paymentData.date.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "date",
                        placeholder: ""
                      },
                      domProps: { value: _vm.paymentData.date },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.paymentData, "date", $event.target.value)
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.paymentData.date.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.paymentData.date.required
                            ? _c("span", [_vm._v("Champ Obligatoire.")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm.paymentData.status.id == "approved"
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("N° OV *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.paymentData.ovNo,
                              expression: "paymentData.ovNo"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.paymentData.ovNo.$error
                          },
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.paymentData.ovNo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.paymentData,
                                "ovNo",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.paymentData.ovNo.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.paymentData.ovNo.required
                                ? _c("span", [_vm._v("Champ Obligatoire.")])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.paymentData.status.id == "approved"
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Document Electronique *")
                        ]),
                        _c(
                          "vue-dropzone",
                          {
                            ref: "myVueDropzone",
                            staticClass: "ebs-upload",
                            attrs: {
                              id: "dropzone",
                              "use-custom-slot": true,
                              options: _vm.dropzoneOptions
                            },
                            on: { "vdropzone-complete": _vm.afterComplete },
                            model: {
                              value: _vm.ovFile,
                              callback: function($$v) {
                                _vm.ovFile = $$v
                              },
                              expression: "ovFile"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "dropzone-custom-content" },
                              [
                                _c("i", {
                                  staticClass:
                                    "display-4 text-muted fas fa-upload"
                                }),
                                _vm._v(" "),
                                _c("br"),
                                _c("h4", [
                                  _vm._v(
                                    "Déposez les fichiers ici ou cliquez pour télécharger."
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Commentaires")
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paymentData.comments,
                          expression: "paymentData.comments"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { name: "", id: "", cols: "30", rows: "3" },
                      domProps: { value: _vm.paymentData.comments },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.paymentData,
                            "comments",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _c("br"),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fas fa-save" }),
                    _vm._v(" Enregistrer ")
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "Ajouter des attachements",
            modalClass: "fullWidthModal"
          },
          on: { close: _vm.handleCloseAssignAttachmentsModalEvent },
          model: {
            value: _vm.showAssignAttachmentsModal,
            callback: function($$v) {
              _vm.showAssignAttachmentsModal = $$v
            },
            expression: "showAssignAttachmentsModal"
          }
        },
        [
          _c(
            "form",
            [
              _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v(
                  "Le système prend en considération que les opérations validés. "
                ),
                _c("br")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", {}, [
                    _c("div", {}, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(" Liste des attachements "),
                        _c("div", { staticClass: "float-right" }, [
                          _c("span", [
                            _vm._v(
                              "TTC Facture: " + _vm._s(_vm.amounts.invoiceAmnt)
                            )
                          ]),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(
                              "Total Attachements: " +
                                _vm._s(_vm.amounts.selectedAmt)
                            )
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "ml-3",
                              class: "text-" + _vm.amounts.diffClass
                            },
                            [
                              _vm._v(
                                "Différence: " + _vm._s(_vm.amounts.diffAmnt)
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table mb-0" }, [
                          _c("thead", { staticClass: "thead-light" }, [
                            _c("tr", [
                              _c("th", [_vm._v("ID")]),
                              _c("th", [_vm._v("N° Attachement")]),
                              _c("th", [_vm._v("Date")]),
                              _c("th", [_vm._v("Montant total")]),
                              _c("th", [_vm._v("Status")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.attachments, function(
                              attachment,
                              index
                            ) {
                              return _c("tr", { key: attachment.id }, [
                                _c(
                                  "th",
                                  { attrs: { scope: "row" } },
                                  [
                                    _c("b-form-checkbox", {
                                      staticClass:
                                        "custom-checkbox-primary mb-3",
                                      attrs: {
                                        value: index,
                                        "unchecked-value": "not_accepted",
                                        checked: ""
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.handleSingleAttachmentClick(
                                            attachment
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          to: {
                                            name: "invoicing.display",
                                            params: { uid: attachment.uuid }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(attachment.ref) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(attachment.dates.generatedAt))
                                ]),
                                _c("td", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(attachment.total_amnt.withCurrency)
                                    )
                                  ])
                                ]),
                                _c("td", [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        attachment.status.rendered
                                      )
                                    }
                                  })
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.addAttachmentToInvoice($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-plus" }), _vm._v(" Ajouter ")]
                )
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Annuler la facture N°: " + _vm.invoiceData.ref },
          model: {
            value: _vm.events.showInvoiceCancelModal,
            callback: function($$v) {
              _vm.$set(_vm.events, "showInvoiceCancelModal", $$v)
            },
            expression: "events.showInvoiceCancelModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.cancelInvoice($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Raison d'annulation")
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cancelModal.comment,
                        expression: "cancelModal.comment"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "",
                      required: "",
                      id: "",
                      cols: "30",
                      rows: "3"
                    },
                    domProps: { value: _vm.cancelModal.comment },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.cancelModal,
                          "comment",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _c("br"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger float-right",
                      attrs: { type: "submit" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-window-close" }),
                      _vm._v(" Annuler ")
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }